import React from 'react'
import "./Products.css"
import { Product } from "../../../../components/index"
import { Container } from 'react-bootstrap'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { Link } from "react-router-dom"
import '@splidejs/react-splide/css';

const Products = ({ contentData, url, translationsData, stylesData }) => {

    const relatedData = contentData.related || [];

  return (
    <Container className='details-products'>
        <h1 className='products-title'>{translationsData.related_products}</h1>
        <div className='details-productscontainer'>
            <Splide
                    options = {{
                      rewind: true,
                      arrows: true,
                      type: "loop",
                      pagination: false,
                      perPage: '4',
                      perMove: '1',
                      gap: '10px',
                      breakpoints: {
                        992: {
                            perPage: '3',
                        },
                        768: {
                            perPage: '2',
                        },
                        576: {
                            perPage: '1',
                        }
                      }
                    }}>

                {
                    relatedData.map((item, index) => (
                        <SplideSlide key={index}>
                            <a href={`/product/${item.id}`}>
                                <Product 
                                    url={url}
                                    stylesData={stylesData}
                                    title={item.name} 
                                    bg={item.background_color} 
                                    image={url + item.image} 
                                    specification={item.specifications}
                                    desc={item.specifications?.map((specitem, index) => (
                                        <div className='related-details' key={index}>
                                          <h2>{specitem.specification.specification}</h2>
                                          <h3>{specitem.value}</h3>
                                        </div>
                                    ))}
                                />
                            </a>
                        </SplideSlide>
                    ))
                }
            </Splide>
        </div>
    </Container>
  )
}

export default Products