import React, { useEffect } from 'react';
import './Branches.css'; // Import your custom CSS file
import { Agent } from '../../components';
import { Container, Row, Col } from 'react-bootstrap';


const Branches = ({ contentData }) => {
  const branchesData = contentData.branches || [];



  return (
    <div className='agents'>
      <Container>
        <h1 className='agents-title'>Our Branches</h1>
        <Row>
          {branchesData.map((item, index) => (
            <Col md={6} key={index}>
              <Agent
                title={item.name}
                para={item.information.map((info, index) => (
                  <p key={index}>{info.value}</p>
                ))}
              />
            </Col>
          ))}
        </Row>
       
      </Container>
    </div>
  );
};

export default Branches;