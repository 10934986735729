import React from 'react'
import "./Footer.css"
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const Footer = ({ url, footerData, stylesData }) => {
  return (
    <div className='footer' style={{ background: `url(${url + stylesData.style_background_blue_big}) no-repeat`}}>
      <img id='logo2' src={url + footerData.footer_logo} />
        <Container>
          <div className='footer-content'>
            {/* <h1>{footerData.footer_title}</h1> */}
            <img src={url + footerData.footer_slogan} className='footer-img' />
            <div className='footer-para'>
              <p  dangerouslySetInnerHTML={{ __html: footerData.footer_description }}></p>
            </div>
            <div className='footer-links'>
              <a href='/contact'>
                <h1 className='footer-link'>Contact Us</h1>
              </a>
              <a href='/privacy'>
                <h1 className='footer-link'>Prviacy Policy</h1>
              </a>
              <a href='/cookies'>
                <h1 className='footer-link'>Cookies</h1>
              </a>
            </div>
            <span>{footerData.footer_copyright}</span>
          </div>
        </Container>
    </div>
  )
}

export default Footer