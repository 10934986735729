import React from 'react';
import "./AboutUs.css"
import { About, Product, Quality, Ingredients } from '../../features/AboutUs/index'
import { Categories, Herosection } from '../../components'




const AboutUs = ({ stylesData, contentData, url, innerPageData, translationsData, setSelectedCategoryId }) => {
  return (
    <div className='aboutus-container'>

        <Herosection title={innerPageData.name} bg={url + innerPageData.image} />
        <About
          contentData={contentData}
          stylesData={stylesData}
          url={url}
        />
        <Product
          url={url}
          contentData={contentData}
          stylesData={stylesData}
        />
        <Quality 
          url={url}
          contentData={contentData}
        />
        <Ingredients
          url={url}
          contentData={contentData}
        />
        <div className='about-categories'>
          <Categories 
            contentData={contentData} 
            url={url} 
            translationsData={translationsData} 
            setSelectedCategoryId={setSelectedCategoryId} 
          />
        </div>
    </div>
  )
}

export default AboutUs