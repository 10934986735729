import React from 'react'
import "./Privacy.css"
import { Herosection } from "../../components"
import { Container } from 'react-bootstrap'

const Privacy = ({ innerPageData, url, stylesData }) => {
    return (
        <div className='privacy'>
            <Herosection title={innerPageData.name} bg={url + innerPageData.image} />
            <div className='privacy-content'>
                <img id="privacy-up" src={url + stylesData.style_background_white} />
                <Container>
                    <div 
                        className='description privacy-description' 
                        dangerouslySetInnerHTML={{ __html: innerPageData.description }}>
                    </div>
                </Container>
            </div>
        </div>
    )
}

export default Privacy
