import React, { useState, useEffect, useRef } from 'react';
import './Agents.css';
import { Agent } from '../../components';
import { Col, Container, Row } from 'react-bootstrap';
import { Tooltip } from 'react-tooltip';

const Agents = ({ contentData, translationsData }) => {
  let agentsData = contentData.agents || [];
  let newAgentsData = [];
  let duplicateAgents = []; // New array to store agents with duplicate latitude and longitude

  agentsData.forEach((e1) => {
    let found = false;
    newAgentsData.forEach((e2) => {
      if (
        e1.latitude !== undefined &&
        e1.longitude !== undefined &&
        e1.latitude === e2.latitude &&
        e1.longitude === e2.longitude
      ) {
        e2.agents.push({
          name: e1.name,
          information: e1.information
        });
        found = true;
      }
    });

    if (!found) {
      newAgentsData.push({
        latitude: e1.latitude,
        longitude: e1.longitude,
        agents: [
          {
            name: e1.name,
            information: e1.information
          }
        ]
      });
    } else {
      duplicateAgents.push({
        name: e1.name,
        information: e1.information
      });
    }
  });

  const syrianAgentsData = contentData.syria_agents || [];
  const [selectedAgent, setSelectedAgent] = useState(null);
  const agentDetailsRef = useRef(null);

  useEffect(() => {
    // Add event listener to hide agent-details when clicking outside
    const handleClickOutside = (event) => {
      if (agentDetailsRef.current && !agentDetailsRef.current.contains(event.target)) {
        setSelectedAgent(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handlePointClick = (agent) => {
    const selectedAgents = getAgentsByCoordinates(agent.latitude, agent.longitude);
    setSelectedAgent(selectedAgents);
  };

  const getAgentsByCoordinates = (latitude, longitude) => {
    return agentsData.filter((agent) => agent.latitude === latitude && agent.longitude === longitude);
  };

  return (
    <div className='agents'>
      <Container>
        <h1 className='agents-title'>{translationsData.haseeb_agent_information}</h1>
        <Row>
          {agentsData.map((item, index) => (
            <Col md={6} key={index}>
              <Agent
                title={item.name}
                para={item.information.map((info, index) => (
                  <p key={index}>{info.value}</p>
                ))}
              />
            </Col>
          ))}
        </Row>
      </Container>
      <h1 className='agents-title'>{translationsData.agents_in_syria}</h1>
      <Container className='agents-map-container'>
        <Row>
          {syrianAgentsData.map((item, index) => (
            <Col md={6} key={index}>
              <Agent
                title={item.name}
                para={item.information.map((info, index) => (
                  <p key={index}>{info.value}</p>
                ))}
              />
            </Col>
          ))}
        </Row>
        <div className='map-container'>
          <img
            src='http://res.cloudinary.com/slzr/image/upload/v1500321012/world-map-1500_vvekl5.png'
            alt='World Map'
          />
          {agentsData.map((item, index) => (
            <div
              style={{
                position: 'absolute',
                left: `${item.longitude || 0}%`,
                top: `${item.latitude || 0}%`
              }}
              key={index}
              className='point radar_animation'
              onClick={() => handlePointClick(item)}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
          ))}

          {selectedAgent && selectedAgent.length > 0 && (
            <div
              ref={agentDetailsRef}
              className='agent-details'
              style={{
                position: 'absolute',
                top: `${selectedAgent[0].latitude || 0}%`,
                left: `${selectedAgent[0].longitude || 0}%`
              }}
            >
              {selectedAgent.map((agent, index) => (
                <div key={index}>
                  <h2>{agent.name}</h2>
                  {agent.information.map((info, infoIndex) => (
                    <p key={infoIndex}>{info.value}</p>
                  ))}
                </div>
              ))}
            </div>
          )}

          <Tooltip place='top' type='light' effect='float' />
        </div>
      </Container>
    </div>
  );
};

export default Agents;