import React, { useRef, useEffect } from 'react';
import "./Hero.css";
import { Col, Row } from 'react-bootstrap';
import * as THREE from 'three';
import { Canvas, useFrame } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';

const Model = ({ productModel, url, productData }) => {
  const object_model = {
    mtl: url + productModel.mtl,
    obj: url + productModel.obj,
    colors: url + productData.product_model_color,
    reflection: url + productModel.reflection,
    displacement: url + productModel.displacement,
  };

  const modelRef = useRef();
  const controlsRef = useRef();
  const rotationSpeed = 0.01;

  useFrame(() => {
    if (modelRef.current) {
      modelRef.current.rotation.y += rotationSpeed;
    }
    if (controlsRef.current) {
      controlsRef.current.update();
    }
  });

  useEffect(() => {
    const mtlLoader = new MTLLoader();
    const objLoader = new OBJLoader();

    mtlLoader.load(object_model.mtl, (materials) => {
      materials.preload();
      objLoader.setMaterials(materials);
      objLoader.load(object_model.obj, (object) => {
        object.scale.set(0.01, 0.01, 0.01);
        object.position.set(0, 0, 0);

        object.traverse((child) => {
          if (child instanceof THREE.Mesh) {
            child.material.side = THREE.DoubleSide;
          }
        });

        const material = object.children[0].material;

        const textureLoader = new THREE.TextureLoader();
        textureLoader.load(object_model.colors, (texture) => {
          texture.encoding = THREE.sRGBEncoding;
          material.map = texture;
          material.needsUpdate = true;
        });

        textureLoader.load(object_model.reflection, (specularMap) => {
          material.specularMap = specularMap;
          material.needsUpdate = true;
        });

        textureLoader.load(object_model.displacement, (bumpMap) => {
          material.bumpMap = bumpMap;
          material.needsUpdate = true;
        });

        modelRef.current.add(object);
      });
    });

    return () => {
      if (modelRef.current) {
        modelRef.current.clear();
      }
    };
  }, [object_model]);

  return (
    <group ref={modelRef}>
      <OrbitControls ref={controlsRef} enableZoom={false} enableRotate={true} enablePan={false} />
      <ambientLight intensity={2} />
      <hemisphereLight skyColor={0xffffff} groundColor={0xffffff} intensity={1.5} />
      <pointLight position={[0, 0, 5]} intensity={5} />
      <pointLight position={[0, 0, 4]} intensity={3} />
      <pointLight position={[0, 0, 6]} intensity={2} />
      <pointLight position={[0, 3, 3]} intensity={0.5} />
      <pointLight position={[3, 0, 3]} intensity={0.5} />
      <pointLight position={[3, 3, 0]} intensity={0.5} />
    </group>
  );
};


const Hero = ({ contentData, url, stylesData }) => {
  const productData = contentData.product || {};
  const productModel = productData.product_model || {};

  return (
    <Row className='hero-details'>
      <Col
        md={6}
        className='hero-details-content'
        style={{
          background: `url(${url + stylesData.product_background}) no-repeat`,
          backgroundColor: `${productData.background_color}`,
        }}
      >
        <h1>{productData.name}</h1>
        {
          productModel === null || productData.product_model_color === null ? (
            <div className='hero-details-product'>
              <img src={url + productData.image} alt={productData.name} />
            </div>
          ) : (
            <div className='hero-first-content'>
              <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center" }}>
              <Canvas camera={{ position: [0, 0, 5] }} gl={{ antialias: true, gammaOutput: true }}>
                <Model productModel={productModel} productData={productData} url={url} />
              </Canvas>
              </div>
            </div>
          )
        }
      </Col>
      <Col
        md={6}
        className='hero-details-img'
        style={{ background: `url(${productData.product_details_image ? url + productData.product_details_image : "/static/media/coffedetails.0a821e1c79d7289a5db7.jpg"}) no-repeat` }}
      ></Col>
      <img id='hero-details-footer' src={url + stylesData.style_background_white} alt="footer" />
    </Row>
  );
};

export default Hero;