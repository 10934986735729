import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Cookies from 'js-cookie';
import { Footer, Navpages } from './layouts/index';
import { Home, AboutUs, ContactUs, Products, Category, ProductDetails, Branches, Agents, JoinOurTeam, Privacy, CookiesPage } from './pages/index';
import { FloatBtn } from './components';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'animate.css';
import './App.css';
import CookieConsent from 'react-cookie-consent'; 

function App() {
  const [loading, setLoading] = useState(true);
  const url = 'https://haseebadmin.moadvertising.net';
  const apiVersion = '/api/v1';
  const pageUrl = window.location.pathname;

  const lastWordIndex = pageUrl.lastIndexOf('/') + 1;
  let pageName = pageUrl.substr(lastWordIndex);
  const [navbarData, setNavbarData] = useState([]);
  const [footerData, setFooterData] = useState({});
  const [logos, setLogos] = useState({});
  const [contentData, setContentData] = useState({});
  const [innerPageData, setInnerPageData] = useState({});
  const [translationsData, setTranslationsData] = useState({});
  const [seoSettingsData, setSeoSettingsData] = useState({});
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [stylesData, setStylesData] = useState({});
  const [selectedLanguage, setSelectedLanguage] = useState('en');


  const [showCookieConsent, setShowCookieConsent] = useState(false);

  useEffect(() => {
    const hasConsent = Cookies.get('myCookieConsent');
    setShowCookieConsent(!hasConsent);
  }, []);

  const handleAcceptCookies = () => {
    Cookies.set('myCookieConsent', true, { expires: 7 });
    setShowCookieConsent(false);
  };

  const handleRejectCookies = () => {
    Cookies.remove('myCookieConsent'); // Remove the consent cookie
    setShowCookieConsent(false); // Hide the consent popup
  };

  useEffect(() => {
    AOS.init({
      once: true,
    });
  }, []);

  const options = {
    headers: {
      'Accept-Language': Cookies.get('selectedLanguage'),
    },
  };
  useEffect(() => {
    const fetchData = async (pageName) => {
      try {
        setLoading(true);
        const apiUrl = `${url}${apiVersion}/content-by-page/${pageName}`;
        const response = await fetch(apiUrl, options);
        const data = await response.json();
        const navbar = data?.data?.navbar?.data || [];
        const footer = data?.data?.footer || '';
        const content = data?.data?.content || '';
        const innerPage = data?.data?.inner_page || '';
        const translations = data?.data?.translations || '';
        const styles = data?.data?.styles || '';
        const seoSettings = data?.data?.['seo-settings'] || '';
        setNavbarData(navbar);
        setFooterData(footer);
        setContentData(content);
        setInnerPageData(innerPage);
        setTranslationsData(translations);
        setSeoSettingsData(seoSettings);
        setStylesData(styles);
        setLogos(data?.data?.logos || '');
        setLoading(false);
      } catch (error) {
        // console.log(error);
        setLoading(false);
      }
    };

    if (['', 'home', undefined].includes(pageName)) {
      pageName = 'home';
    } else if (pageUrl.includes('category/')) {
      let categoryId = pageUrl.substr(lastWordIndex);
      pageName = `products?category_id=${categoryId}`;
    } else if (pageUrl.includes('product/')) {
      let productId = pageUrl.substr(lastWordIndex);
      pageName = `product?product_id=${productId}`;
    }
    fetchData(pageName);
  }, [pageName]);

  useEffect(() => {
    const updateFavicon = () => {
      const link = document.querySelector('link[rel="icon"]');
      link.setAttribute('href', `${url + logos.logo_favicon}`);
    };

    const updateTitle = () => {
      const mainTitle = `${seoSettingsData?.main_title || ''} ${seoSettingsData?.separator || ''} ${
        innerPageData?.name || ''
      }`;
      let formattedTitle = '';
    
      if (Cookies.get('selectedLanguage') == 'ar') {
        formattedTitle = mainTitle.trim() || 'حسيب';
      } else {
        formattedTitle = mainTitle.trim() || 'Haseeb';
      }
    
      document.title = formattedTitle;
    };

    updateFavicon();
    updateTitle();
  }, [logos, pageName, seoSettingsData, innerPageData, selectedLanguage]);

  useEffect(() => {
    const updateLanguage = () => {
      const htmlElement = document.documentElement;
      if (selectedLanguage === 'ar') {
        htmlElement.setAttribute('dir', 'rtl');
        import('./arabic.css')
          .then(() => {
            // CSS file has been successfully imported
          })
          .catch((error) => {
            console.log('Failed to import Arabic CSS file:', error);
          });
      } else {
        htmlElement.setAttribute('dir', 'ltr');
      }
    };
    updateLanguage();
  }, [selectedLanguage]);

  return (
    <BrowserRouter>
      <div className="app">
        {loading ?  (
          <div class="coffee-wrap">
            <div class="coffee">
              <div class="cup">
                <span class="steam"></span>
                <span class="steam"></span>
                <span class="steam"></span>
                <div class="cup-handle"></div>
              </div>
              <p>Loading</p>
            </div>
          </div>
        ) : (
          <>
            <FloatBtn />
            {logos.logo && (
              <Navpages
                navbarData={navbarData}
                logos={logos}
                url={url}
                stylesData={stylesData}
                selectedLanguage={selectedLanguage}
                setSelectedLanguage={setSelectedLanguage}
              />
            )}
            <Routes>
              <Route index element = { 
                <Home 
                  contentData={contentData} 
                  url={url} 
                  innerPageData={innerPageData} 
                  translationsData={translationsData} 
                  setSelectedCategoryId={setSelectedCategoryId} 
                  selectedCategoryId={selectedCategoryId} 
                  apiVersion={apiVersion} 
                  stylesData={stylesData}
                  options={options}
                /> 
              }/>
              <Route path = "/about" element = { 
                <AboutUs 
                  contentData={contentData} 
                  url={url} 
                  innerPageData={innerPageData} 
                  translationsData={translationsData} 
                  setSelectedCategoryId={setSelectedCategoryId} 
                  selectedCategoryId={selectedCategoryId} 
                  apiVersion={apiVersion} 
                  stylesData={stylesData}
                /> 
              }/>
              <Route path = "/contact" element = { 
                <ContactUs 
                  contentData={contentData} 
                  url={url} 
                  innerPageData={innerPageData} 
                  translationsData={translationsData} 
                  setSelectedCategoryId={setSelectedCategoryId} 
                  selectedCategoryId={selectedCategoryId} 
                  apiVersion={apiVersion} 
                  stylesData={stylesData}
                /> 
              }/>
              <Route path = "/products" element = { 
                <Products 
                  contentData={contentData} 
                  url={url} 
                  innerPageData={innerPageData} 
                  translationsData={translationsData} 
                  setSelectedCategoryId={setSelectedCategoryId} 
                  selectedCategoryId={selectedCategoryId} 
                  apiVersion={apiVersion} 
                  stylesData={stylesData}
                /> 
              }/>
              <Route path = "/category/:id" element = { 
                <Category 
                  contentData={contentData} 
                  url={url} 
                  innerPageData={innerPageData} 
                  translationsData={translationsData} 
                  setSelectedCategoryId={setSelectedCategoryId} 
                  selectedCategoryId={selectedCategoryId} 
                  apiVersion={apiVersion} 
                  stylesData={stylesData}
                  options={options}
                /> 
              }/>
              <Route path = "/product/:id" element = { 
                <ProductDetails 
                  contentData={contentData} 
                  url={url} 
                  innerPageData={innerPageData} 
                  translationsData={translationsData} 
                  setSelectedCategoryId={setSelectedCategoryId} 
                  selectedCategoryId={selectedCategoryId} 
                  apiVersion={apiVersion} 
                  stylesData={stylesData}
                /> 
              }/>
              <Route path= '/branches' element = {
                <Branches
                  contentData={contentData}
                />
              }/>
              <Route path='/agents' element = {
                <Agents
                  translationsData={translationsData} 
                  contentData={contentData} 
                />
              }/>
              <Route path='/join' element = {
                <JoinOurTeam />
              }/>
              <Route path='/privacy' element = {
                <Privacy 
                  url={url}
                  innerPageData={innerPageData}
                  stylesData={stylesData}
                />
              }/>
              <Route path='/cookies' element = {
                <CookiesPage 
                  url={url}
                  innerPageData={innerPageData}
                  stylesData={stylesData}
                />
              }/>
            </Routes>
            <Footer footerData={footerData} url={url} stylesData={stylesData} navbarData={navbarData}/>
            <CookieConsent
              location="bottom"
              buttonText="Accept"
              declineButtonText="Decline"
              enableDeclineButton
              cookieName="myCookieConsent"
              expires={7}
              onAccept={handleAcceptCookies}
              onDecline={handleRejectCookies}
              style={{ background: '#2B373B' }}
              buttonStyle={{ color: '#fff', fontSize: '16px', background: "#10539e", padding: "7px 22px 9px" }}
              declineButtonStyle={{ color: '#fff', fontSize: '16px', background: 'transparent', border: "1px solid #10539e", padding: '7px 22px 9px' }} // Style for the reject button
              buttonWrapperClasses="cookie-consent-button"
              declineButtonClasses="cookie-consent-decline-button"
              overlay={true}
            >
              Cookies help us deliver the best experience on our website. By using our website, you agree to the use of cookies.
            </CookieConsent>
          </>
        )}
      </div>
    </BrowserRouter>
  );
}

export default App;