import React, { useState, useEffect } from 'react';
import './Product.css';

const Product = ({ title, title2, image, bg, desc, specification, productData, stylesData, url }) => {
  const [loadingImage, setLoadingImage] = useState(true);
  const hasProductData = productData && productData.length > 0;
  const opacity = hasProductData ? 1 : 0.5;

 

  useEffect(() => {
    const img = new Image();
    img.src = image;
    img.onload = () => {
      setLoadingImage(false);
    };
    img.onerror = () => {
      setLoadingImage(false);
      // Handle image loading error if needed
    };
  }, [image]);
  console.log(loadingImage)


  return (
    <div className="product" style={{  opacity: `${opacity}`, background: `url(${url + stylesData.product_background}) no-repeat`, backgroundColor: `${bg}` }}>
      <div className="product-title">
        <h1>{title}</h1>
        <p>{title2}</p>
      </div>
      <div className="product-img" data-aos="zoom-in" data-aos-duration="1800">
        {loadingImage ? (
          <div className="product-loading">Loading...</div>
        ) : (
          <img src={image} alt="Product"/>
        )}
      </div>
      {specification && specification.length >= 1 && (
        <div className="product-before">{desc}</div>
      )}
    </div>
  );
};


export default Product;