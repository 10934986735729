import React, { useRef, useEffect } from 'react'
import "./Hero.css"
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import { Col, Row } from 'react-bootstrap'

import * as THREE from 'three';
import { Canvas, useFrame } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import { MTLLoader } from 'three/addons/loaders/MTLLoader.js';
import { OBJLoader } from 'three/addons/loaders/OBJLoader.js';
import _mtl from '../../../../assets/model/Hasseeb.mtl';
import _obj from '../../../../assets/model/Hasseeb.obj';
import _colors from "../../../../assets/model/Color.png";
import _reflection from "../../../../assets/model/Reflection.png";
import _displacement from "../../../../assets/model/Displacement.png";


const Model = ({homepage_product_showcase, url}) => {

  let object_model = {
    mtl: _mtl,
    obj: _obj,
    colors: _colors,
    reflection: _reflection,
    displacement: _displacement,
  }

  console.log(homepage_product_showcase)
  
  if (homepage_product_showcase.product_model){
    object_model = {
      mtl: url + homepage_product_showcase.product_model.mtl,
      obj: url + homepage_product_showcase.product_model.obj,
      colors: url + homepage_product_showcase.product_model_color,
      reflection: url + homepage_product_showcase.product_model.reflection,
      displacement: url + homepage_product_showcase.product_model.displacement,
    }
  }

  const modelRef = useRef();
  const controlsRef = useRef();
  const rotationSpeed = 0.01;

  useFrame(() => {
    if (modelRef.current) {
      modelRef.current.rotation.y += rotationSpeed;
    }
    if (controlsRef.current) {
      controlsRef.current.update();
    }
  });

  useEffect(() => {
    const mtlLoader = new MTLLoader();
    const objLoader = new OBJLoader();

    mtlLoader.load(object_model.mtl, (materials) => {
      materials.preload();
      objLoader.setMaterials(materials);
      objLoader.load(object_model.obj, (object) => {
        object.scale.set(0.01, 0.01, 0.01);
        object.position.set(0, 0, 0);

        object.traverse((child) => {
          if (child instanceof THREE.Mesh) {
            child.material.side = THREE.DoubleSide;
          }
        });

        const material = object.children[0].material;

        const textureLoader = new THREE.TextureLoader();
        textureLoader.load(object_model.colors, (texture) => {
          texture.encoding = THREE.sRGBEncoding;
          material.map = texture;
          material.needsUpdate = true;
        });

        textureLoader.load(object_model.reflection, (specularMap) => {
          material.specularMap = specularMap;
          material.needsUpdate = true;
        });

        textureLoader.load(object_model.displacement, (bumpMap) => {
          material.bumpMap = bumpMap;
          material.needsUpdate = true;
        });

        modelRef.current.add(object);
      });
    });

    return () => {
      if (modelRef.current) {
        modelRef.current.clear();
      }
    };
  }, [object_model]);

  return (
    <group ref={modelRef}>
      <OrbitControls ref={controlsRef} enableZoom={false} enableRotate={true} enablePan={false} />
      <ambientLight intensity={2} />
      <hemisphereLight skyColor={0xffffff} groundColor={0xffffff} intensity={1.5} />
      <pointLight position={[0, 0, 5]} intensity={5} />
      <pointLight position={[0, 0, 4]} intensity={3} />
      <pointLight position={[0, 0, 6]} intensity={2} />
      <pointLight position={[0, 3, 3]} intensity={0.5} />
      <pointLight position={[3, 0, 3]} intensity={0.5} />
      <pointLight position={[3, 3, 0]} intensity={0.5} />
    </group>
  );
};


const Hero = ({ contentData, url, innerPageData, stylesData }) => {
  const gallery = innerPageData.gallery || [];
  const productData = contentData.homepage_product_showcase || {};

  return (
    <div className='hero'>
      <img id='coffe' src={url + contentData.homepage_coffee_beans_image} data-aos="fade-down" data-aos-duration="1800" />
      <img id='group320' src={url + stylesData.style_background_white} />
      <img id='group309' src={url + stylesData.style_background_white} />
      <Row className='hero-content'>
        <Col md={6} className='hero-first' style={{ background: `${productData.background_color}` }}>
          <img src={url + stylesData.product_background} />
          <div className='hero-first-content'>
            <div style={{}}>
              <Canvas Canvas camera={{ position: [0, 0, 5] }} gl={{ antialias: true, gammaOutput: true }}>
                <Model homepage_product_showcase={contentData.homepage_product_showcase} url={url}/>
              </Canvas>
            </div>
          </div>
        </Col>
        <Col md={6} className='hero-second'>
          <Splide
            options={{
              rewind: true,
              arrows: false,
              pagination: true,
              autoplay: true,
              type: "loop",
            }}>
            {gallery.map((item, index) => (
              <SplideSlide key={index}>
                <img src={url + item.image} />
              </SplideSlide>
            ))}
          </Splide>
        </Col>
      </Row>
      <div className='hero-responsive'>
          <div className='hero-first-content'>
            <div div style={{}}>
              <Canvas camera={{ position: [0, 0, 5] }} gl={{ antialias: true, gammaOutput: true }}>
                <Model homepage_product_showcase={contentData.homepage_product_showcase} url={url}/>
              </Canvas>
            </div>
            </div>
            <div className='hero-second'>
                <Splide
                    options = {{
                      rewind: true,
                      arrows: false,
                      pagination: true,
                      autoplay: true,
                      type: "loop",
                    
                    }}>
                    {
                      gallery.map((item, index) => (
                        <SplideSlide key={index}>
                          <img src={url + item.image} />
                        </SplideSlide>
                      ))
                    }
                </Splide>
            </div>
        </div>
    </div>
  )
}

export default Hero