import React from 'react'
import "./CookiesPage.css"
import { Container } from 'react-bootstrap'
import { Herosection } from '../../components'

const CookiesPage = ({ innerPageData, url, stylesData }) => {
    return (
        <div className='cookies'>
    
            <Herosection title={innerPageData.name} bg={url + innerPageData.image} />
            <div className='cookies-content'>
                <img id="cookies-up" src={url + stylesData.style_background_white} />
                <Container>
                    <div 
                        className='description' 
                        dangerouslySetInnerHTML={{ __html: innerPageData.description }}>
                    </div>
                </Container>
            </div>
           
        </div>
    )
}

export default CookiesPage
