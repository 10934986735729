import React from 'react'
import "./Product.css"


const Product = ( {title, bg, image, stylesData, url, desc, specification} ) => {
  console.log(stylesData)
  return (
    <div style={{ background: `url(${url + stylesData.product_background}) no-repeat`, backgroundColor: `${bg}` }} className='coffe-product'>
        <h1>{title}</h1>
        <div className='coffe-product-img'>
          <img src={image} />
        </div>
        {specification && specification.length >= 1 && (
        <div className="product-related-details">{desc}</div>
        )}
    </div>
  )
}

export default Product